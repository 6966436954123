import { memo } from 'react';
import StyledColor from './color.styled';

const ColorItem = ({ color, color2, color3, handleClick, className, title }) => {
    const style = {};

    if (color3) {
        style.background = `linear-gradient(to right, ${color} 0%, ${color} 33%, ${color2} 34%, ${color2} 66%, ${color3} 67%, ${color3} 100%)`;
    } else if (color2) {
        style.background = `linear-gradient(to right, ${color} 0%, ${color} 50%, ${color2} 51%, ${color2} 100%)`;
    } else {
        style.background = color;
    }

    return (
        <StyledColor
            color={color}
            color2={color2}
            color3={color3}
            style={style}
            onClick={handleClick}
            title={title}
            className={className}
        />
    );
};

export default memo(ColorItem);
